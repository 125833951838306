import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import BeerIcon from "../images/svg/external-link.svg";

const BlogHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "header-me.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 90, webpQuality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className="container blog-header">
      <Link to="/">
        <div className="brand-name">
          <div style={{ width: 70 }}>
            <Img
              fluid={data.image.childImageSharp.fluid}
              style={{
                maxWidth: 70,
                width: "100%",
                borderRadius: 15,
                display: "block",
                margin: "auto",
              }}
              alt="This is me"
            />
          </div>
          <span>Vijit Ail</span>
        </div>
      </Link>
      <a
        className="header-cta"
        target="_blank"
        href="https://mentoring.vijitail.dev?utm_source=vijit_ail_blog"
      >
        <span>Developer Mentoring</span>
        <div>
          <BeerIcon style={{ height: 22, width: 22, marginLeft: 10 }} />
        </div>
      </a>
    </div>
  );
};

export default BlogHeader;
