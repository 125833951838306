import React from "react";
import GmailIcon from "../images/svg/gmail.svg";
import MediumIcon from "../images/svg/medium.svg";
import GithubIcon from "../images/svg/github.svg";
import DribbbleIcon from "../images/svg/dribbble.svg";

const GetInTouch = () => {
  return (
    <div className="section get-in-touch">
      <div className="container">
        <h3 className="section-title">
          <span role="img" aria-label="contact emoji">
            🤙
          </span>{" "}
          Get in touch with me
        </h3>
        <div className="row">
          <a
            href="mailto:vijit2ail@gmail.com?subject=Hey%20There!"
            className="git-cta"
            title="Email"
          >
            <GmailIcon style={{ height: 20, width: 20, marginRight: 10 }} />
            vijit2ail@gmail.com
          </a>

          <a
            href="https://github.com/vijitail"
            className="git-cta"
            title="Github"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GithubIcon style={{ height: 20, width: 20, marginRight: 10 }} />{" "}
            Check out my Github
          </a>

          <a
            href="https://dribbble.com/Vijit_Ail"
            className="git-cta"
            title="Dribbble"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DribbbleIcon style={{ height: 20, width: 20, marginRight: 10 }} />{" "}
            Designs on Dribbble
          </a>

          <a
            href="https://medium.com/@vijit2ail"
            className="git-cta"
            title="Medium"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MediumIcon style={{ height: 20, width: 20, marginRight: 10 }} />{" "}
            Follow me on Medium
          </a>
        </div>
        <div className="text site-info">
          This site is built with{" "}
          <a
            href="https://www.gatsbyjs.org/"
            className="text-primary"
            rel="noopener noreferrer"
          >
            Gatsby
          </a>{" "}
          and powered by{" "}
          <a
            href="https://www.netlify.com/"
            className="text-primary"
            rel="noopener noreferrer"
          >
            Netlify
          </a>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
