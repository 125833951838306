import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import "../scss/primsjs-themes/dracula.css";
import "../scss/blog.scss";

import GetInTouch from "../components/GetInTouch";
import BlogHeader from "./BlogHeader";
import SEO from "../components/seo";

import FacebookIcon from "../images/svg/facebook.svg";
import LinkedinIcon from "../images/svg/linkedin.svg";
import TwitterIcon from "../images/svg/twitter.svg";
import BeerIcon from "../images/svg/beer.svg";

const BlogTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, excerpt } = markdownRemark;
  const url =
    typeof window !== "undefined"
      ? window.location.origin + window.location.pathname
      : "";
  return (
    <>
      <SEO
        description={frontmatter.description || excerpt}
        title={frontmatter.title}
        keywords={frontmatter.keywords}
        canonical={frontmatter.canonical}
        image={
          frontmatter.featuredImage &&
          frontmatter.featuredImage.childImageSharp.fluid.src
        }
      />
      <BlogHeader />
      <div className="container blog-post-container">
        <div className="blog">
          <h1>{frontmatter.title}</h1>
          <p className="blog-date">
            {new Date(frontmatter.date).toDateString()}
          </p>
          {frontmatter.featuredImage && (
            <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} />
          )}

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <div>
            <p>
              If you read this far, and liked the post, please support and
              share.
            </p>

            <div className="blog-share">
              <div>
                <FacebookShareButton
                  quote="Hey! Check out this awesome post"
                  url={url}
                >
                  <FacebookIcon
                    style={{ height: 45, width: 45, marginRight: 10 }}
                  />
                </FacebookShareButton>
                <LinkedinShareButton
                  quote="Hey! Check out this awesome post"
                  url={url}
                >
                  <LinkedinIcon
                    style={{ height: 45, width: 45, marginRight: 10 }}
                  />
                </LinkedinShareButton>
                <TwitterShareButton
                  quote="Hey! Check out this awesome post"
                  url={url}
                >
                  <TwitterIcon
                    style={{ height: 45, width: 45, marginRight: 10 }}
                  />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
    </>
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        date
        title
        keywords
        canonical
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1210, quality: 90, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default BlogTemplate;
